<template>
  <div class="page" v-if="show">
    <!-- <header class="header">
      <div class="center">微拍预展平台</div>
    </header> -->
    <div class="main index">
      <div class="banner">
        <img class="bg" :src="shop.bg" alt="" />
        <!-- <img src="/huilongge.jpeg" class="bg" alt=""> -->
        <div class="container">
          <img class="logo" :src="shop.logo" alt="" />
          <div class="title" @click="$router.push('/shopInfo/' + shop_id)">
            {{ shop.shop_name }}
            <img src="right.png" alt="" />
          </div>
        </div>
      </div>

      <div class="desc">
        {{ shop.desc }}
      </div>

      <div class="list-title">
        <div class="i"></div>
        <div class="title">即将开拍</div>
      </div>
      <div class="list" v-if="list.length">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="goto(item.id)"
        >
          <div class="title">{{ item.title }}</div>
          <img class="thumb" :src="item.thumb" alt="" />
          <div class="info">
            <div class="time">
              开拍时间：<b
                >{{ item.time.date }}
                <!-- （{{ item.time.week }}） -->
                {{ item.time.time }}</b
              >
            </div>
            <!-- <div class="amount">
              共 <span>{{ item.amount }}</span> 件
            </div> -->
          </div>
        </div>
      </div>
      <div class="tips" v-else>暂无</div>

      <div class="list-title">
        <div class="i"></div>
        <div class="title">往期拍卖</div>
      </div>
      <div class="list3" v-if="list2.length">
        <div
          class="item"
          v-for="(item, index) in list2"
          :key="index"
          @click="goto(item.id)"
        >
          <div class="thumb">
            <img :src="item.thumb" alt="" />
          </div>
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div class="info">
              <div class="time">
                开拍时间：<b>{{ item.time.date }} {{ item.time.time }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tips" v-else>暂无</div>
    </div>
  </div>
</template>

<style lang='scss' scord>
</style>

<script>
export default {
  data() {
    return {
      show: false,
      shop_id: null,
      shop: {},
      list: [],
      list2: [],
    };
  },
  async mounted() {
    localStorage.removeItem("hash");

    this.shop_id = this.$route.params.shop_id;
    if (!this.shop_id) return this.$router.push("/noshop");

    const r = await this.$axios.get("/shop/index?shop_id=" + this.shop_id);
    // console.log(r);
    this.show = true;
    
    if (r.status == 1) {
      this.shop = r.shop;
      this.list = r.list;
      this.list2 = r.list2;
    }

    const that = this;
    // --------------- 获取signature ---------------
    const fullPath = this.$config.domain + this.$route.fullPath;

    this.signature = await this.$axios.post("/getSignature", {
      url: fullPath,
    });

    wx.config({
      debug: false,
      appId: this.signature.appId,
      timestamp: Number(this.signature.timestamp),
      nonceStr: this.signature.nonceStr,
      signature: this.signature.signature,
      jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"],
    });

    wx.ready(function () {
      //--------------- 分享 ---------------
      wx.updateAppMessageShareData(
        {
          title: that.shop.shop_name + " -- 微拍预展平台", // 分享标题
          desc: that.shop.desc, // 分享描述
          link: fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.shop.logo, // 分享图标
        },
        function (res) {
          console.log(res, 11122);
          //这里是回调函数
        }
      );

      wx.updateTimelineShareData(
        {
          title: that.shop.shop_name + " -- 微拍预展平台", // 分享标题
          link: fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.shop.logo, // 分享图标
        },
        function (res) {
          //这里是回调函数
        }
      );
    });
    wx.error(function (res) {
      console.error(res, "---------------错误信息---------------");
    });
  },

  methods: {
    goto(id) {
      this.$router.push("/auction/" + this.shop_id + "?id=" + id);
    },
  },
};
</script>